import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { navigate } from "gatsby"
import { useDispatch } from "react-redux"
import { Layout } from "../components/Layout"
import { Section, LoginForm } from "../style/globalStyles"
import { userLogin } from "../state"
export default function Login() {
  const loginData = useSelector((state) => state.login)
  // console.log(loginData)

  // const loginBool = Boolean(loginData)
  useEffect(() => {
    // if (loginData.loggedin) {
    //   navigate("/")
    // }
  })

  const [state, setState] = useState({})
  const [enable, setEnable] = useState(false)
  const dispatch = useDispatch()
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
    if (state.emailId && state.password) {
      setEnable(true)
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    // console.log("login state", state)
    dispatch(userLogin(state))
  }
  return (
    // <Layout>
    <main style={{ padding: "1rem" }}>
      <Section
        style={{
          padding: 0,
          flexWrap: "nowrap",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          // height: "calc(70vh - 5rem)",
        }}
      >
        <LoginForm onSubmit={handleSubmit}>
          <h1>Doctorite Dashboard Login</h1>
          <br />
          <br />
          <label htmlFor="emailId">
            E-Mail
            {/* <p>{doctorData.firstName}</p> */}
            <input
              id="emailId"
              name="emailId"
              type="email"
              placeholder={"Enter e-mail address"}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="password">
            Password
            {/* <p>{doctorData.firstName}</p> */}
            <input
              id="password"
              name="password"
              type="password"
              placeholder={"Enter password"}
              onChange={handleChange}
            />
          </label>
          <button
            disabled={loginData.loading}
            type="submit"
            aria-disabled={enable ? true : false}
            style={{
              backgroundColor: state ? "#00af91" : "lightgray",
              padding: "0rem",
              padding: "0.8rem 1.5rem",
              height: "fit-content",
              textAlign: "center",
              justifyContent: "center",
              borderRadius: "2rem",
              margin: "auto 1rem",
              width: "100%",
              // cursor: enable ? "pointer" : "default",
            }}
          >
            {loginData.loading ? "Logging you in..." : "Login"}
          </button>
        </LoginForm>
      </Section>
    </main>
    // </Layout>
  )
}
